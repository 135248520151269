import {Component} from '@angular/core';
import '@esri/calcite-components/dist/components/calcite-icon';
import {AuthService} from './services/auth.service';
import {NavigationEnd, Router} from "@angular/router";
import {filter, Subscription} from "rxjs";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  // imports: [MatToolbarModule],
  // schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppComponent {
  page = 'query-page';
  pagesStatic = [
    {'route': '/query-page', 'title': 'Query Page'},
    {'route': '/boring-location-diagram', 'title': 'Boring Location Diagram'}
  ];
  selectedPage = this.pagesStatic[0];
  linkPage = this.pagesStatic[1];
  subs: Array<Subscription> = [];

  constructor(public authService: AuthService, public router: Router, private titleService: Title) {
    this.subs[0] = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe((event) => {
      this.page = this.router.url.split('/')[1];
      this.selectedPage = (this.page === 'boring-location-diagram') ? this.pagesStatic[1] : this.pagesStatic[0];
      this.linkPage = (this.page === 'boring-location-diagram') ? this.pagesStatic[0] : this.pagesStatic[1];
      titleService.setTitle(this.selectedPage.title);
    });
  }
}
