<!--<div class="allMapView">-->
<!--<div class="widgetPanelContainer" >-->
<app-widget-panel class="widgetPanelContainer" [@openClose]="isOpen ? 'open' : 'closed'"
                  [mapView]="mapView" *ngIf="tabs | async as t" [tabs]="t"></app-widget-panel>

<!--</div>-->
<div class="collapseToggle" (click)="isOpen = !isOpen"
    [matTooltip]="isOpen ? 'Click to close panel.' : 'Click to open panel.'"
    [matTooltipPositionAtOrigin]="true">
  <!--    <button mat-icon-button (click)="isOpen = false">-->
  <mat-icon *ngIf="isOpen">
    chevron_left
  </mat-icon>
  <mat-icon *ngIf="!isOpen" >
    chevron_right
  </mat-icon>
  <!--    </button>-->
</div>
<!--  <div class="draggable-area" [style.left]="draggableAreaLeftText" [style.max-width]="draggableAreaWidthText">-->
<!--    <div style="position:absolute; height: 90%; width: 20px; display: flex; align-items: center;"-->
<!--         [style.left]="dragLocation" [style.cursor]="dragCursor" cdkDragBoundary=".draggable-area"-->
<!--         (dblclick)="isOpen = false" cdkDragLockAxis="x" cdkDrag #dragBar-->
<!--          (cdkDragMoved)="dragMove($event)" (cdkDragStarted)="captureStartingWidth($event)" (cdkDragEnded)="dragEnd()">-->
<!--      <mat-icon style="transform: rotate(90deg)" matTooltip="Drag to resize panel. Double click to reset to original size.">drag_handle</mat-icon>-->
<!--    </div>-->
<!--<div class="esriMapContainer">-->
<app-webmap (mapViewReady)="setMapView($event)" [webMapID]="webmapId | async"
            [webmapWidgets]="webmapWidgets | async"></app-webmap>
<!--</div>-->
<!--  </div>-->
<!--</div>-->
