export const environment = {
  portalSettings: {
    appId: 'q2LXt2cp5ZKWk1zT',
    url: 'https://gis.innovateteam.com/arcgis'
  },
  boringsServices: {
    url: 'https://gis.innovateteam.com/arcgis/rest/services/ECS/BoringLocations/FeatureServer',
    layersIndex: {
      borings: 0,
      sectionLines: 2,
      siteLocationAreas: 4,
    },
    tablesIndex: {
      boringsSectionLinesRelated: 5,
    },
  },
  sectionLineLabels: {
    url: 'https://gis.innovateteam.com/arcgis/rest/services/ECS/SectionLineLabels/MapServer',
    layersIndex: {
      sectionLineLabels: 2
    }
  },
  projectsProposalsServices: {
    url: 'https://gis.innovateteam.com/arcgis/rest/services/ECS/ProjectsAndProposals/FeatureServer',
    layersIndex: {
      projectsAndProposals: 0
    },
    tablesIndex: {
      externalFiles: 1
    }
  },
  lookupsServices: {
    url: 'https://gis.innovateteam.com/arcgis/rest/services/ECS/Lookups/FeatureServer',
    layersIndex: {
      proposedOffices: 0,
      existingOffices: 1,
      territories: 2,
      statePlanes: 5
    },
    tablesIndex: {
      projectsUniqueValues: 3,
      lookups: 4,
    }
  },
  queryPageWebMapId: 'bdfb7248dd994d0e8cdfb5ba5001ece4',
  boringLocationDiagramWebMapId: 'fad45eccbdd54d81adbc2a0d046c5199',
  queryPrintService: {
    url: 'https://gis.innovateteam.com/arcgis/rest/services/ECS/CustomPrint/GPServer',
    tasks: {
      exportWebMap: 'Export%20Web%20Map',
      getLayoutTemplatesInfo: 'Get%20Layout%20Templates%20Info'
    }
  },
  exportSectionLinesUrl: 'https://gis.innovateteam.com/arcgis/rest/services/ECS/SaveGDM/GPServer/SaveGDM',
  importerServiceUrl: 'https://gis.innovateteam.com/arcgis/rest/services/ECS/SaveExternalFiles/GPServer',
  flatBufferServiceUrl: 'https://gis.innovateteam.com/arcgis/rest/services/ECS/FlatBuffer2/GPServer/Flat%20Buffer',
  deleteServiceUrl: 'https://gis.innovateteam.com/arcgis/rest/services/ECS/DeleteImport/GPServer',
  externalFilesRastersServiceUrl: 'https://gis.innovateteam.com/arcgis/rest/services/ECS/ExternalFiles_Rasters/ImageServer',
  externalFilesRoot: 'https://itgisdev.blob.core.windows.net/ecs',
  use_auth: true,
  ecs360: {
    signin: 'http://localhost:5000/signin',
    upload: 'http://localhost:5000/upload',
    george: 'http://localhost:5000/george'
  },
  basemapGroup: "c7430f400ebf46879d51834058ac855c",
};
