import {
  AfterViewInit,
  Component,
  ComponentRef,
  Input, OnChanges,
  OnInit,
  QueryList, SimpleChanges,
  ViewChildren,
  ViewContainerRef
} from '@angular/core';
import MapView from '@arcgis/core/views/MapView';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {BaseWidgetComponent} from '../widgets/base-widget/base-widget.component';
import {Tab} from '../types/tab';
import {ProjectAndProposalService} from '../services/project-and-proposal.service';
import {tap} from 'rxjs';

@Component({
  selector: 'app-widget-panel',
  templateUrl: './widget-panel.component.html',
  styleUrls: ['./widget-panel.component.scss']
})
export class WidgetPanelComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() mapView!: MapView;
  @Input() tabs!: Tab[];
  currentTabs!: Tab[];
  @ViewChildren('container', {read: ViewContainerRef}) tabContainers!: QueryList<ViewContainerRef>;
  public currentIndex: number = 0;
  tabPersistentGraphicsLayers!: string[];

  constructor(public router: Router, public route: ActivatedRoute, public projectProposalService: ProjectAndProposalService) {
    route.data.forEach((data) => {
      Object.keys(data).filter((key) => {
        return key === 'tabPersistentGraphicsLayers'
      }).forEach((key) => {
        this.tabPersistentGraphicsLayers = data[key];
      });
    });
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.setCurrentTabIndex(params);
    })
    this.listenToProjectChange().subscribe()
  }

  // when a project or proposal changes we need to reset query params so navigation between tabs
  // isn't confusing. eg. If someone was on delete SLL tab/nav and change projects it will still
  // be on SSL Delete (query param) if we dont clear out the query params on the tabs.
  listenToProjectChange() {
    return this.projectProposalService.selected.pipe(
      tap(() => this.currentTabs.forEach(t => t.queryParams = {}))
    )
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['tabs'] &&
      (changes['tabs'].previousValue === undefined && changes['tabs'].currentValue !== undefined ||
        changes['tabs'].previousValue.length !== changes['tabs'].currentValue.length)) {
      this.currentTabs = this.tabs;
      this.setCurrentTabIndex(this.route.snapshot.params);
    }
    if ('mapView' in changes &&
      changes['mapView'].previousValue === undefined
      && changes['mapView'].currentValue !== undefined) {
      this.currentTabs.filter(t => t.componentRef).forEach(t => this.setMapView(t.componentRef));
    }
  }

  ngAfterViewInit() {
    this.loadTab(this.currentIndex);
  }

  setMapView(componentRef: ComponentRef<BaseWidgetComponent>) {
    componentRef.setInput('mapView', this.mapView);
  }

  async tabChanged(e: MatTabChangeEvent) {
    if (this.currentTabs) {
      // capture current query params so we can easily go back to them
      if (this.currentTabs[this.currentIndex]) {
        this.currentTabs[this.currentIndex].queryParams = this.route.snapshot.queryParams;
      }
      this.router.navigate(['..', this.currentTabs[e.index].route],
        {relativeTo: this.route, queryParams: this.currentTabs[e.index].queryParams})
      await this.loadTab(e.index);
      this.notifyTabChanged(e.index);
    }
  }

  async setCurrentTabIndex(params: Params) {
    const widget = params['widget'];
    // Only reset the widget panel active tab if the new route is included in introTabs
    const newIntroTabIndex = this.currentTabs.findIndex((t: Tab) => t.route === widget);
    if (newIntroTabIndex !== -1) {
      this.currentIndex = newIntroTabIndex;
    }
  }

  async loadTab(index: number) {
    const t = this.tabContainers.get(index);
    // make sure nothing has been rendered in requested tab...there might be a better way.
    if (t?.length === 0) {
      const c = await this.currentTabs[index].component();
      const componentRef: ComponentRef<BaseWidgetComponent> = t?.createComponent(c);
      this.currentTabs[index].componentRef = componentRef;
      if (this.mapView) {
        this.setMapView(componentRef);
      }
      // Emit intro and more tabs to the More widget only
      // if (this.currentTabs[index].componentRef.componentType === MoreWidgetsComponent) {
      //   this.currentTabs[index].componentRef.setInput('tabs', this.tabs);
      // }
    }
  }

  private notifyTabChanged(visibleIndex: number) {
    this.currentTabs.forEach((tab, i) => tab.componentRef?.instance.tabChanged(i === visibleIndex))
  }

  tabClicked() {
    this.currentTabs[this.currentIndex].componentRef?.instance.tabClicked();
  }
}
