import {Component, OnDestroy, OnInit} from '@angular/core';
import {MapViewComponent} from '../map-view/map-view.component';
import {combineLatestWith, filter, map, Subscription, tap} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {ProjectAndProposalService} from '../services/project-and-proposal.service';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import {environment} from '../../environments/environment';
import {ProjectProposal} from '../types/project-proposal';
import {getLayerFromUrl} from '../utilities';
import {ExternalFile} from '../types/external-file';
import MapImage from '@arcgis/core/layers/support/MapImage';
import MapImageLayer from '@arcgis/core/layers/MapImageLayer';
import KMLLayer from '@arcgis/core/layers/KMLLayer';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';

@Component({
  selector: 'app-bld-map-view',
  templateUrl: '../map-view/map-view.component.html',
  styleUrls: ['../map-view/map-view.component.scss']
})
export class BldMapViewComponent extends MapViewComponent implements OnInit, OnDestroy {
  selectedProjectId!: string;
  boringLayer!: FeatureLayer;
  sectionLinesLayer!: FeatureLayer;
  siteLocationAreasLayer!: FeatureLayer;
  sectionLineLabels!: MapImageLayer;

  projectSub!: Subscription;

  constructor(override activatedRoute: ActivatedRoute, public router: Router,
              private proposalService: ProjectAndProposalService) {
    super(activatedRoute);
    this.listenToProjectId(proposalService);
  }

  ngOnDestroy() {
    this.projectSub?.unsubscribe();
    // this might be too tightly coupled but the proposal is key to this map view
    this.proposalService?.groupLayer?.destroy();
  }

  override setMapView(mapView: __esri.MapView): void {
    super.setMapView(mapView);
    this.mapView.when(() => {
      this.captureBoringLayers();
      this.mapView.map.add(this.proposalService.projectProposalGraphicsLayer);
      this.projectSub = this.listenToProjectSelection();
    })
  }

  override listenToRoutingData() {
    this.tabs = this.activatedRoute.data.pipe(
      combineLatestWith(this.activatedRoute.params),
      map(([d, p]) => {
        if (this.activatedRoute.snapshot.params['id'] === 'search') {
          return [d['tabs'][0]]
        }
        return d['tabs']
      })
    );
  }

  listenToProjectSelection() {
    return this.proposalService.selected.pipe(
      tap(p => {
        if (!p) {
          this.clearBLDLayers();
        } else {
          this.mapView.goTo({geometry: p?.geometry, zoom: 18});
          this.setBLDLayerDefinitionExpressions(p as ProjectProposal);
          this.proposalService.getExternalFiles(
            p.attributes.GlobalID as string,
            this.mapView
          );
        }
      })
    ).subscribe()
  }

  private listenToProjectId(proposalService: ProjectAndProposalService) {
    proposalService.selected.subscribe(p => this.selectedProjectId = (p?.attributes.prid as string))
    this.activatedRoute.params.pipe(
      tap(p => {
        if (p['id'] && p['id'] !== 'search' && p['id'] !== this.selectedProjectId) {
          this.loadProject(p['id'])
        } else if (p['id'] && p['id'] === 'search') {
          this.clearProject();
        }
      }),
    ).subscribe()

  }

  async setBLDLayerDefinitionExpressions(p: ProjectProposal) {
    this.boringLayer.definitionExpression = `RegID_FK = '${p.attributes.prid}'`;
    this.sectionLinesLayer.definitionExpression = `RegID_FK = '${p.attributes.prid}'`;
    this.siteLocationAreasLayer.definitionExpression = `Locations_FK = '${p.attributes.GlobalID}'`;
    await this.sectionLineLabels.when();
    this.sectionLineLabels.sublayers.forEach(l => {
      l.definitionExpression = `RegID_FK = '${p.attributes.prid}'`;
    });
  }

  private captureBoringLayers() {
    this.boringLayer = getLayerFromUrl(
      this.mapView,
      environment.boringsServices.url,
      environment.boringsServices.layersIndex.borings
    ) as FeatureLayer;
    this.sectionLinesLayer = getLayerFromUrl(
      this.mapView,
      environment.boringsServices.url,
      environment.boringsServices.layersIndex.sectionLines
    ) as FeatureLayer;
    this.siteLocationAreasLayer = getLayerFromUrl(
      this.mapView,
      environment.boringsServices.url,
      environment.boringsServices.layersIndex.siteLocationAreas
    ) as FeatureLayer;

    this.sectionLineLabels = getLayerFromUrl(this.mapView, environment.sectionLineLabels.url) as MapImageLayer;

  }

  private async clearBLDLayers() {
    this.boringLayer.definitionExpression = '1=0';
    this.sectionLinesLayer.definitionExpression = '1=0';
    this.siteLocationAreasLayer.definitionExpression = '1=0';
    await this.sectionLineLabels.when();
    this.sectionLineLabels.sublayers.forEach(l => {
      l.definitionExpression = '1=0';
    });
  }


  private async loadProject(id: string) {
    this.proposalService.projectProposalGraphicsLayer.removeAll();
    const f = await this.proposalService.selectProjectProposal(id)
    this.proposalService.projectProposalGraphicsLayer.add(f);
  }

  private clearProject() {
    this.proposalService.clear();
    this.proposalService.projectProposalGraphicsLayer.removeAll();
  }
}
