import {Injectable} from '@angular/core';
import MapView from '@arcgis/core/views/MapView';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import {environment} from '../../environments/environment';
import {BehaviorSubject} from 'rxjs';
import {ProjectProposal} from '../types/project-proposal';
import {ExternalFile} from '../types/external-file';
import KMLLayer from '@arcgis/core/layers/KMLLayer';
import esriRequest from '@arcgis/core/request';
import Layer from '@arcgis/core/layers/Layer';
import Graphic from '@arcgis/core/Graphic';
import Field from '@arcgis/core/layers/support/Field';
import GroupLayer from '@arcgis/core/layers/GroupLayer';
import MediaLayer from '@arcgis/core/layers/MediaLayer';
import ImageElement from '@arcgis/core/layers/support/ImageElement';
import ExtentAndRotationGeoreference from '@arcgis/core/layers/support/ExtentAndRotationGeoreference';
import FeatureSet from '@arcgis/core/rest/support/FeatureSet';
import {submitJob} from '@arcgis/core/rest/geoprocessor';
import MapImageLayer from '@arcgis/core/layers/MapImageLayer';
import SimpleRenderer from '@arcgis/core/renderers/SimpleRenderer';
import Symbol2D = __esri.symbols.Symbol2D;
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import MosaicRule from "@arcgis/core/layers/support/MosaicRule";
import ImageryLayer from "@arcgis/core/layers/ImageryLayer";


function createSubClass<T>(layer: any, external_file_id: number, group_layer: GroupLayer, kmlUrl?: string): any {
  return layer.createSubclass({
    external_file_id,
    async deleteFile() {
      group_layer.remove(this);
      const r = await submitJob(`${environment.deleteServiceUrl}/DeleteImportedFile`, {objectid: this.external_file_id});
      return r.waitForJobCompletion();
    },
    kmlUrl
  })
}

@Injectable({
  providedIn: 'root'
})
export class ProjectAndProposalService {
  selected: BehaviorSubject<ProjectProposal | null> = new BehaviorSubject<ProjectProposal | null>(null)
  url = `${environment.projectsProposalsServices.url}/${environment.projectsProposalsServices.layersIndex.projectsAndProposals}`;
  projectProposalGraphicsLayer = new GraphicsLayer({listMode: 'hide'});

  layer = new FeatureLayer({
    url: this.url,
    outFields: ['*']
  });
  externalFilesTable: FeatureLayer = new FeatureLayer({
    url: `${environment.projectsProposalsServices.url}/${environment.projectsProposalsServices.tablesIndex.externalFiles}`,
  })
  groupLayer!: GroupLayer;

  // ideally this would be moved somewhere else but at the moment where isn't obvious too me
  // we cannot do the same as we are doing with other components since we are not directly adding these to the dom
  // perhaps webmap service becomes a collection of functions and is not a provider or used for passing data around
  // public setupProjectProposalSearch(mapView: MapView, snapshot: ActivatedRouteSnapshot) {
  //   this.mapView = mapView;
  //   const sources = [
  //     {
  //       layer: this.layer,
  //       searchFields: ['searchProj'],
  //       exactMatch: false,
  //       suggestionsEnabled: true,
  //       placeholder: 'Search Office:Project/Proposal#',
  //       displayField: 'searchProj'
  //     }
  //   ];
  //
  //   const searchWidget = new Search({
  //     view: mapView,
  //     sources,
  //     locationEnabled: false,
  //     popupEnabled: false,
  //     includeDefaultSources: false,
  //     // autoSelect: false,
  //     goToOverride: (view, goToParams) => {
  //     }
  //   })
  //   mapView.ui.add(searchWidget, 'top-right');
  //
  //   if (snapshot.params['id'] && snapshot.params['id'] !== 'search') {
  //     mapView.when(() => searchWidget.search(snapshot.params['id']))
  //   }
  //
  //   searchWidget.on('select-result', r => {
  //     this.router.navigate(['boring-location-diagram', r.result.feature.attributes.searchProj, 'borings-creator']);
  //     //hack to keep from loading feature and running goto on mapview that is about to be destroyed
  //     if (snapshot.url.length > 1) {
  //       this.getFullFeature(r.result.feature.attributes.OBJECTID);
  //     }
  //   });
  // }
  async selectProjectProposal(id: string | number) {
    let f = await this.layer.queryFeatures({
      // objectIds: [id as number],
      where: `prid = '${id}'`,
      returnGeometry: true,
      outFields: ['*']
    });
    this.selected.next(f.features[0]);
    // only works if service is using simple renderer
    if (this.layer.renderer.type === 'simple') {
      f.features[0].symbol = (this.layer.renderer as SimpleRenderer).symbol;
    } else if (this.layer.renderer.hasOwnProperty('defaultSymbol')) {
      /// attempt to catch default symbol otherwise
      // @ts-ignore
      f.features[0].symbol = this.layer.renderer.defaultSymbol as Symbol2D
    }

    return f.features[0];
  }

  getCurrentID(p: ProjectProposal) {
    return p.attributes.prPROJNO ? p.attributes.prPROJNO : p.attributes.ProposalNumber;
  }

  getCurrentType(p: ProjectProposal) {
    return p.attributes.prPROJNO ? 'project' : 'proposal';
  }

  clear() {
    this.selected.next(null);
  }

  captureGroupLayer(mapView: MapView) {
    this.groupLayer = this.getGroupLayerByTitle(mapView, 'Imported Files');
    if (this.groupLayer) {
      this.groupLayer.removeAll(); // 'Imported Files' group layer saved to web map has two empty feature layers we get rid of.
    } else {
      this.groupLayer = new GroupLayer({ title: 'Imported Files', listMode: "hide"});
      mapView.map.layers.add(this.groupLayer);
    }
  }

  getGroupLayerByTitle(mapView: MapView, title: string) {
    return mapView.map.layers.find(lyr => {
      return lyr.title === title && lyr.type === 'group';
    }) as GroupLayer;
  }

  async getExternalFiles(GlobalID: string, mapView: MapView) {
    // if this is running again its a new proposal/project
    this.captureGroupLayer(mapView);

    const results = await this.externalFilesTable.queryFeatures(
      {where: `Location_FK = '${GlobalID}'`, outFields: ['*']}
    );
    if (results.features.length > 0) {
      this.groupLayer.listMode = 'show';
    }
    results.features.map((f: ExternalFile) => {
      if (f.attributes.File_Type.toLowerCase() === 'cad' && f.attributes.File_Name.includes('.')) {
        // to handle legacy imports
        f.attributes.URL = `${environment.externalFilesRoot}/${f.attributes.Network_Location}/${f.attributes.File_Name.split('.').slice(0, -1).join('.')}`;
      } else {
        f.attributes.URL = `${environment.externalFilesRoot}/${f.attributes.Network_Location}/${f.attributes.File_Name}`;
      }
      return f;
    }).forEach(f => {
      this.loadExternalFile(mapView, f);
    });
  }

  async getExternalFile(objectId: number, mapView: MapView, zoomToExtent = false) {
    const results = await this.externalFilesTable.queryFeatures(
      {objectIds: [objectId], outFields: ['*']});
    if (this.groupLayer.listMode === 'hide') {
      this.groupLayer.listMode = 'show';
    }
    results.features.map((f: ExternalFile) => {
      f.attributes.URL = `${environment.externalFilesRoot}/${f.attributes.Network_Location}/${f.attributes.File_Name}`;
      return f;
    }).forEach(f => {
      this.loadExternalFile(mapView, f, zoomToExtent);
    });
  }

  async loadExternalFile(mapView: MapView, f: ExternalFile, zoomToExtent = false) {
    let layers!: Layer[];
    switch (f.attributes.File_Type.toLowerCase()) {
      case 'jpg':
      case 'jpeg': {
        layers = this.handleJPG(f);
        break;
      }
      case 'kml':
      case 'kmz': {
        layers = this.handleKML(f);
        break;
      }
      case 'shp': {
        layers = await this.handleSHP(f);
        break;
      }
      case 'cad': {
        layers = await this.handleCAD(f);
        break;
      }
      case 'rest': {
        layers = this.handleREST(f);
        break;
      }
    }

    if (layers) {
      this.removeLayersFromGroupLayer(layers);
      this.addLayersToGroupLayer(layers, zoomToExtent, mapView);

    } else {
      console.log("No handler found for ", f.attributes.File_Name, f.attributes.OBJECTID)
    }
  }

  removeLayersFromGroupLayer(layers: any[]) {
    layers.forEach(layer => {
      // @ts-ignore
      const foundLayer = this.groupLayer.layers.find(l => l.external_file_id === layer.external_file_id)
      if (foundLayer) {
        this.groupLayer.remove(foundLayer)
      }
    })
  }

  addLayersToGroupLayer(layers: Layer[], zoomToExtent: boolean, mapView: MapView) {
    this.groupLayer.addMany(layers);
    // mapView.map.layers.addMany(layers);
    if (zoomToExtent) {
      this.goToExtent(mapView, layers);
    }
  }

  async goToExtent(mapView: MapView, layers: Layer[]) {
    if (layers[0] instanceof GroupLayer) {
      layers = layers[0].layers.toArray();
    }
    const layerViews = await Promise.all(layers.map(l => mapView.whenLayerView(l)));
    const extents = layerViews.map(l => l.layer.fullExtent);
    mapView.goTo(extents);
  }

  handleJPG(f: ExternalFile) {
    // Extend the ImageryLayer class with an external_files_id property and method to delete a selected GeoJPEG
    // from ExternalFiles_Rasters, Locations_ExtFiles, and blob storage. Actual actions depend on gp script.
    const ExternalFilesImageryLayer = createSubClass(ImageryLayer, f.attributes.OBJECTID, this.groupLayer);
    const mosaicRule = new MosaicRule({
      method: 'lock-raster',
      lockRasterIds: [f.attributes.Mosaic_ID]
    });
    const imgLayer = new ExternalFilesImageryLayer({
      url: `${environment.externalFilesRastersServiceUrl}`,
      title: `${f.attributes.File_Name}`,
      mosaicRule: mosaicRule,
      opacity: 0.7,
    });

    // Set layer's extent to the locked raster's footprint or else "Go to extent" will go to all rasters in the dataset.
    const footprintQuery = {
      where: `Network_Location = '${f.attributes.Network_Location}' AND Name = '${f.attributes.File_Name}'`,
      returnGeometry: true,
      f: 'json'
    }

    // Modify delete gp service to delete
    // modify go to extent layer list actions - NO NEED
    imgLayer.queryRasters(footprintQuery).then((fs: FeatureSet) => {
      if (fs?.features?.length === 1) {
        imgLayer.fullExtent = fs.features[0].geometry.extent;
      }
    });
    return [imgLayer];
  }

  handleKML(f: ExternalFile): KMLLayer[] {
    const url = `${f.attributes.URL}.${f.attributes.File_Type.toLowerCase()}`;
    const ExternalFileKMLLayer = createSubClass(KMLLayer, f.attributes.OBJECTID, this.groupLayer, url);
    const layer = new ExternalFileKMLLayer({url});
    return [layer]
  }

  async handleSHP(f: ExternalFile): Promise<FeatureLayer[]> {
    let zipURL = `${f.attributes.URL}.zip`;
    const body = new FormData();
    body.append('sourceUrl', zipURL);
    body.append('filetype', 'shapefile');
    body.append('publishParameters', '{}');
    body.append('f', 'json');
    return esriRequest(`${environment.portalSettings.url}/sharing/rest/content/features/generate`,
      {body}
    ).then(({data}) => {
      let sourceGraphics: Graphic[] = [];

      const layers = data.featureCollection.layers.map((layer: any) => {

        const graphics = layer.featureSet.features.map((feature: any) => {
          return Graphic.fromJSON(feature);
        })
        sourceGraphics = sourceGraphics.concat(graphics);
        const ExternalFileFeatureLayer = createSubClass(FeatureLayer, f.attributes.OBJECTID, this.groupLayer);
        return new ExternalFileFeatureLayer({
          objectIdField: "FID",
          title: f.attributes.TOC_FriendlyName,
          source: graphics,
          fields: layer.layerDefinition.fields.map((field: any) => {
            return Field.fromJSON(field);
          })
        });
        // associate the feature with the popup on click to enable highlight and zoom to
      });
      return layers;
    });
  }

  async handleCAD(f: ExternalFile) {
    const layers = await Promise.all(['Point', 'Polyline', 'Polygon'].map(geomName => {
      const jsonURL = `${f.attributes.URL}/${geomName}.json`;
      return esriRequest(jsonURL).then(({data}) => {
        let fs = FeatureSet.fromJSON(data);

        return new FeatureLayer({
          source: fs.features,
          fields: fs.fields,
          spatialReference: fs.spatialReference,
          title: geomName,
        });
      });
    }));
    const ExternalFileGroupLayer = createSubClass(GroupLayer, f.attributes.OBJECTID, this.groupLayer);

    const groupLayer = new ExternalFileGroupLayer({
      title: f.attributes.TOC_FriendlyName,
      layers
    });
    return [groupLayer];
  }

  private handleREST(f: ExternalFile) {
    const url = f.attributes.File_Name;
    var featureServiceRegex = new RegExp(/\/mapserver\/\d*$|\/featureserver\/\d*$/, 'img')
    var mapServiceRegex = new RegExp(/\/mapserver$|\/featureserver$/, 'img')
    var imageServiceRegex = new RegExp(/\/imageserver$/, 'img')

    var ExternalFileLayer;
    if (featureServiceRegex.test(url)) {
      ExternalFileLayer = createSubClass(FeatureLayer, f.attributes.OBJECTID, this.groupLayer);
    } else if (mapServiceRegex.test(url)) {
      ExternalFileLayer = createSubClass(MapImageLayer, f.attributes.OBJECTID, this.groupLayer);
    } else if (imageServiceRegex.test(url)) {
      ExternalFileLayer = createSubClass(MapImageLayer, f.attributes.OBJECTID, this.groupLayer);
    }
    if (ExternalFileLayer) {
      let layer = new ExternalFileLayer({url});
      layer.title = f.attributes.TOC_FriendlyName;
      layer.id = f.attributes.TOC_FriendlyName;
      return [layer]
    }
    return []
  }

  toggleProjectGraphic(visible: boolean) {
    this.projectProposalGraphicsLayer.visible = visible
  }

  // // add method to set rotation of jpg when printing and then reset rotation back to 0 or null when print job started
  // setJPGRotationForPrint(rotation: number) {
  //   this.groupLayer?.layers.forEach(l => {
  //     if (l instanceof MediaLayer && l.source instanceof ImageElement && l.source.georeference instanceof ExtentAndRotationGeoreference) {
  //       l.source.georeference.rotation = rotation;
  //     }
  //   })
  // }
}
