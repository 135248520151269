<mat-tab-group class="widget-panel-nav-div" (selectedTabChange)="tabChanged($event)" [selectedIndex]="currentIndex">
  <mat-tab *ngFor="let tab of currentTabs">
    <ng-template mat-tab-label>
      <div [matTooltip]="tab.tooltip" (click)="tabClicked()" class="full-padding-tab"
          style="display: flex; justify-content: center; align-items: center; width: 100%; height: 48px;">
      <calcite-icon  icon="{{tab.icon}}" scale="m"></calcite-icon>
    </div>
    </ng-template>
    <ng-template #container></ng-template>
  </mat-tab>
</mat-tab-group>
