import {inject, Injectable} from '@angular/core';
import OAuthInfo from '@arcgis/core/identity/OAuthInfo.js';
import IdentityManager from '@arcgis/core/identity/IdentityManager.js';
import {environment} from '../../environments/environment';
import {ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot} from '@angular/router';
// import Portal from '@arcgis/core/portal/Portal.js';
import esriConfig from "@arcgis/core/config";
// import {of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // portal = new Portal({url: environment.portalSettings.url});
  // fullName: string = '';
  userName: string = '';
  efilesUsername: string = '';

  constructor() {
    esriConfig.portalUrl = environment.portalSettings.url
    esriConfig.kmlServiceUrl = `${environment.portalSettings.url}/sharing/kml`;

    if (environment.use_auth) {
      const info = new OAuthInfo({
        appId: environment.portalSettings.appId,
        popup: false,
        expiration: 1440,
        portalUrl: environment.portalSettings.url,
        authNamespace: 'pointFinder'
      });
      IdentityManager.registerOAuthInfos([info]);
      const initIdentity = localStorage.getItem('pointFinder_app_auth');
      if (initIdentity) {
        IdentityManager.initialize(JSON.parse(initIdentity));
      }
    }
  }

  canActivate() {
    return IdentityManager.checkSignInStatus(`${environment.portalSettings.url}`).then(creds => {
      this.userName = creds.userId;
      localStorage.setItem('pointFinder_app_auth', JSON.stringify(IdentityManager.toJSON()))
      return true
    }).catch(() => {
      IdentityManager.getCredential(`${environment.portalSettings.url}`).catch(e => console.log(e));
      return false;
    })
  }

  setEfilesUsername(username: string) {
    this.efilesUsername = username.split('@')[0] as string;
  }
}

export const canActivate: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return environment.use_auth ? inject(AuthService).canActivate() : true;
}
