import MapView from '@arcgis/core/views/MapView';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import Collection from '@arcgis/core/core/Collection';
import esriRequest from '@arcgis/core/request';
import {environment} from '../environments/environment';
import Point from '@arcgis/core/geometry/Point';
import MapImageLayer from '@arcgis/core/layers/MapImageLayer';

export class Utilities {
}

export function getLayerFromUrl(webmap: MapView, url: string, index?: number, type?: "layer" | "table" | "image"): FeatureLayer | MapImageLayer {
  let list: Collection = webmap.map.allLayers;
  if (type === 'table') {
    list = webmap.map.allTables;
  }
  return list.find(l => {
    if (l instanceof FeatureLayer) {
      if (l.url === url && l.layerId === index) {
        return true;
      } else if (l.url === `${url}/${index}`) {
        return true;
      }
    } else if (l instanceof MapImageLayer) {
      return l.url === url;
    }
    return false;
  }) as FeatureLayer | MapImageLayer;
}

export function getLayerDirectly(url: string, index: number): Promise<FeatureLayer> {
  return new FeatureLayer({url: `${url}/${index}`, outFields: ['*']}).load();
}

// upload method for uploading to gp service upload endpoint and returns itemID
export async function upload(file: File, url: string) {
  const formData = new FormData();
  formData.append('file', file);
  formData.set('f', 'json');
  return esriRequest(url, {
    body: formData
  }).then(r => r.data.item.itemID);
}

const statePlaneLookupService = new FeatureLayer({
  url: `${environment.lookupsServices.url}/${environment.lookupsServices.layersIndex.statePlanes}`
});

export async function getStatePlaneWkid(geometry: Point): Promise<number> {
  return statePlaneLookupService.queryFeatures({
    geometry,
    outFields: ['wkid']
  }).then(r => r.features[0].attributes.wkid)
}
