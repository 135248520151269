import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MapViewComponent} from './map-view/map-view.component';
import {canActivate} from './services/auth.service';
import {environment} from '../environments/environment';
import {BldMapViewComponent} from './bld-map-view/bld-map-view.component';

const routes: Routes = [
  {
    path: 'query-page/:widget', component: MapViewComponent,
    canActivate: [canActivate],
    data: {
      webmapId: environment.queryPageWebMapId,
      webmapWidgets: [
        {
          widget: 'Search',
          position: 'top-left',
          params: {locationEnabled: false, popupEnabled: false, suggestionsEnabled: false}
        },
        {widget: 'Zoom', position: 'top-left'},
        {widget: 'Locate', position: 'top-left'},
        {widget: 'Compass', position: 'top-left', params: {icon: 'compass-north-circle'}},
        {widget: 'Home', position: 'top-left'},
        {widget: 'ScaleBar', position: 'bottom-right', params: {unit: 'imperial'}},
        {widget: 'Sketch', position: 'top-right'},
        // {widget: 'BasemapGallery', position: 'top-right', groupId: environment.basemapGroup},
        {
          widget: 'CoordinateConversion',
          position: 'bottom-left',
          params: {
            headingLevel: 1,
            // visibleElements: {
            //   settingsButton: false,
            //   captureButton: false,
            //   expandButton: false,
            //   editButton: false,
            // }
          }
        },
        {widget: 'Measurement', position: 'top-right', params: {linearUnit: 'feet', areaUnit: 'acres'}}
      ],
      tabs: [
        {
          icon: 'search',
          tooltip: 'Project & Proposal Query',
          route: 'search',
          graphicsLayers: ['Project/Proposal Search Graphics'],
          component: () => import('../app/widgets/project-proposal-search/project-proposal-search.component').then(m => m.ProjectProposalSearch)
        },
        {
          icon: 'layers', tooltip: 'Layer List', route: 'layer-list', graphicsLayers: [],
          component: () => import('../app/widgets/layer-list/layer-list.component').then(x => x.LayerListComponent)
        },
        {
          icon: 'print', tooltip: 'Print', route: 'print', graphicsLayers: ['Print Preview'],
          component: () => import('../app/widgets/query-print/query-print.component').then(m => m.QueryPrintComponent)
        },
        {
          icon: 'add-layer', tooltip: 'Add Data', route: 'add-data', graphicsLayers: [],
          component: () => import('../app/widgets/add-data/add-data.component').then(m => m.AddDataComponent)
        }
      ]
    }
  },
  {
    path: 'boring-location-diagram/:id/:widget', component: BldMapViewComponent,
    canActivate: [canActivate],
    data: {
      webmapId: environment.boringLocationDiagramWebMapId,
      webmapWidgets: [
        {
          widget: 'Search',
          position: 'top-left',
          params: {locationEnabled: false, popupEnabled: false, suggestionsEnabled: false}
        },
        {widget: 'Zoom', position: 'top-left'},
        {widget: 'Locate', position: 'top-left'},
        {widget: 'Compass', position: 'top-left', params: {icon: 'compass-north-circle'}},
        {
          widget: 'CoordinateConversion',
          position: 'bottom-left',
          params: {
            headingLevel: 1,
            // visibleElements: {
            //   settingsButton: false,
            //   captureButton: false,
            //   expandButton: false,
            //   editButton: false,
            // }
          }
        },
        {widget: 'Sketch', position: 'top-right'},
        // {widget: 'BasemapGallery', position: 'top-right', groupId: environment.basemapGroup},
        {widget: 'Measurement', position: 'top-right', params: {linearUnit: 'feet', areaUnit: 'acres'}},
        {widget: 'ScaleBar', position: 'bottom-right', params: {unit: 'imperial'}},
      ],
      tabs: [
        {
          icon: 'search', tooltip: 'Select Project/Proposal', route: 'search',
          component: () => import('./widgets/proposal-selector/proposal-selector.component').then(m => m.ProposalSelectorComponent)
        },
        {
          icon: 'pin-plus', tooltip: 'Borings Creator', route: 'borings-creator',
          component: () => import('./widgets/widget-navs/boring-creator-nav/boring-creator-nav.component').then(m => m.BoringCreatorNavComponent)
        },
        {
          icon: 'polygon-vertices', tooltip: 'Site Location Lines Creator', route: 'site-location-lines-creator',
          component: () => import('./widgets/widget-navs/site-location-lines-nav/site-location-lines-nav.component').then(m => m.SiteLocationLinesNavComponent)
        },
        {
          icon: 'point-line', tooltip: 'Section Lines Creator', route: 'section-lines-creator',
          component: () => import('./widgets/widget-navs/section-lines-nav/section-lines-nav.component').then(m => m.SectionLinesNavComponent)
        },
        {
          icon: 'hamburger', tooltip: 'More', route: 'more',
          component: () => import('../app/widgets/more-widgets/more-widgets.component').then(m => m.MoreWidgetsComponent)
        }
      ]
    },
  },
  {path: 'query-page', redirectTo: '/query-page/search'},
  {path: 'boring-location-diagram', redirectTo: '/boring-location-diagram/search/'},
  {path: '**', redirectTo: '/query-page/search', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
