import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import WebMap from '@arcgis/core/WebMap';
import Map from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';
import {WebmapWidgets} from "../types/webmap-widgets";
import BasemapToggle from '@arcgis/core/widgets/BasemapToggle';
import Search from "@arcgis/core/widgets/Search";
import Home from "@arcgis/core/widgets/Home";
import Compass from "@arcgis/core/widgets/Compass";
import ScaleBar from "@arcgis/core/widgets/ScaleBar";
import Extent from "@arcgis/core/geometry/Extent";
import SpatialReference from "@arcgis/core/geometry/SpatialReference";
import Zoom from "@arcgis/core/widgets/Zoom";
import CoordinateConversion from "@arcgis/core/widgets/CoordinateConversion";
import BasemapGallery from '@arcgis/core/widgets/BasemapGallery';
import Expand from '@arcgis/core/widgets/Expand';
import {environment} from '../../environments/environment';
import Portal from '@arcgis/core/portal/Portal';
import PortalBasemapsSource from '@arcgis/core/widgets/BasemapGallery/support/PortalBasemapsSource';
import Locate from '@arcgis/core/widgets/Locate';
import Measurement from '@arcgis/core/widgets/Measurement';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import Sketch from '@arcgis/core/widgets/Sketch';
import {when} from '@arcgis/core/core/reactiveUtils';

@Component({
  selector: 'app-webmap',
  template: '',
  styleUrls: ['./webmap.component.scss']
})
export class WebmapComponent implements OnInit, OnDestroy {
  @Output() mapViewReady: EventEmitter<MapView> = new EventEmitter<MapView>()
  @Input() webMapID: string | null = null;
  @Input() webmapWidgets: WebmapWidgets[] | null = [];
  webmap!: Map;
  mapView!: MapView;
  sketchLayer = new GraphicsLayer({listMode: 'hide'})

  constructor(private elRef: ElementRef) {
  }

  ngOnInit() {
    const container = document.createElement('div')
    container.setAttribute('style', 'height: 100%;');
    this.elRef.nativeElement.appendChild(container)
    this.webmap = new WebMap({
      // basemap: 'satellite'
      portalItem: {id: this.webMapID as string}
    });
    this.mapView = new MapView({
      map: this.webmap,
      container,
      constraints: {
        // Constrain panning to extent of Web Mercator projected bounds https://epsg.io/3857
        geometry: new Extent({
          xmin: -20037508.34,
          xmax: 20037508.34,
          ymin: -20048966.1,
          ymax: 20048966.1,
          spatialReference: SpatialReference.WebMercator
        })
      }
    });
    // keep sketch layer on top of layer list even if not in use
    this.mapView.map.layers.on('after-changes', e => {
      this.mapView.map.add(this.sketchLayer, 9999)
    })

    if (this.webmapWidgets) {
      this.webmapWidgets.forEach(widget => {
        switch (widget.widget) {
          case 'BasemapToggle':
            this.mapView.ui.add(new BasemapToggle({view: this.mapView, ...widget.params}), widget.position);
            break;
          case 'Search':
            this.mapView.ui.add(new Search({view: this.mapView, ...widget.params}), widget.position);
            break;
          case 'Home':
            this.mapView.ui.add(new Home({view: this.mapView, ...widget.params}), widget.position);
            break;
          case 'Compass':
            this.mapView.ui.add(new Compass({view: this.mapView, ...widget.params}), widget.position);
            break;
          case 'ScaleBar':
            this.mapView.ui.add(new ScaleBar({view: this.mapView, ...widget.params}), widget.position);
            break;
          // TODO: remove and replace with a watch function on webmap component to create a smaller, more subtle coordinate tracker.
          case 'CoordinateConversion':
            let ccWidget = new CoordinateConversion({view: this.mapView, ...widget.params});
            // const toRemove = ccWidget.formats.filter(format => format.name !== 'dd');
            // ccWidget.formats.removeMany(toRemove);
            this.mapView.ui.add(new Expand({
              view: this.mapView,
              content: ccWidget,
              expandIcon: 'point',
              expandTooltip: 'Coordinates'
            }), widget.position);
            break;
          case 'Zoom':
            this.mapView.ui.remove('zoom'); // Must remove first because zoom is a default widget
            this.mapView.ui.add(new Zoom({view: this.mapView, ...widget.params}), widget.position);
            break;
          case 'BasemapGallery':
            let content;
            if (widget.groupId) {
              const portal = new Portal({url: environment.portalSettings.url});
              const source = new PortalBasemapsSource({
                portal,
                query: {
                  id: widget.groupId
                }
              });
              content = new BasemapGallery({view: this.mapView, source, ...widget.params})
            } else {
              content = new BasemapGallery();
            }
            const e = new Expand({
              view: this.mapView,
              content,
              expandIcon: 'basemap'
            })
            this.mapView.ui.add(e, widget.position);
            break;
          case 'Locate':
            this.mapView.ui.add(new Locate({view: this.mapView, ...widget.params}), widget.position);
            break;
          case 'Measurement':
            const measurement = new Measurement({view: this.mapView, ...widget.params});
            const c = document.createElement('div');
            c.innerHTML = `<div style="width: 110px; display: flex; flex-direction: row; justify-content: space-between;">
                      <calcite-button scale="s" kind="neutral" id="distance" class="esri-widget--button"
                      title="Distance">
                        <calcite-icon scale="s" icon="measure"></calcite-icon>
                      </calcite-button>
                      <calcite-button scale="s" kind="neutral" id="area" class="esri-widget--button"
                       title="Area">
                        <calcite-icon scale="s" icon="measure-area"></calcite-icon>
                      </calcite-button>
                      <calcite-button scale="s" kind="neutral" id="clear" class="esri-widget--button"
                      title="Clear">
                        <calcite-icon scale="s" icon="trash"></calcite-icon>
                      </calcite-button>
                      </div>
                `;

            const measureExpand = new Expand({
              view: this.mapView,
              content: c,
              expandIcon: 'measure',
              expandTooltip: 'Measurements'
            })
            this.mapView.ui.add(measureExpand, widget.position);
            this.mapView.ui.add(measurement, widget.position)

            measureExpand.when(() => {
              document.getElementById('distance')?.addEventListener('click', () => {
                measurement.activeTool = 'distance'
              })
              document.getElementById('area')?.addEventListener('click', () => {
                measurement.activeTool = 'area'
              })
              document.getElementById('clear')?.addEventListener('click', () => {
                measurement.startMeasurement();
              })
            })
            when(() => !measureExpand.expanded, () => {
              measurement.clear();
            })

            break;
          case 'Sketch':
            const sketch = new Sketch({view: this.mapView, layer: this.sketchLayer})
            this.mapView.ui.add(new Expand({
              view: this.mapView,
              content: sketch,
              expandTooltip: 'Draw'
            }), widget.position);
            break;
        }
      });
    }

    this.mapView.when(() => {
      this.mapViewReady.emit(this.mapView);
      this.deactivateBasemapPopups();
    })
  }

  ngOnDestroy() {
    this.mapView.destroy();
    this.webmap.destroy();
  }

  deactivateBasemapPopups() {
    this.mapView.map.allLayers.forEach(layer => {
      if (layer.title === 'Basemaps' && layer.type === 'group') {
        (layer as any).popupEnabled = false;
        (layer as any).allLayers.forEach((sublayer: any) => {
          sublayer.popupEnabled = false;
        });
      }
    });
  }
}
