import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {WidgetPanelComponent} from '../widget-panel/widget-panel.component';
import {ActivatedRoute} from "@angular/router";
import {map, Observable, ReplaySubject, Subject} from 'rxjs';
import {Tab} from '../types/tab';
import MapView from '@arcgis/core/views/MapView';
import {WebmapWidgets} from "../types/webmap-widgets";
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({width: '475px'})),
      state('closed', style({width: '0px'})),
      transition('open => closed', [
        animate('0.5s')
      ]),
      transition('closed => open', [
        animate('0.5s')
      ])
      ]
    )
  ]
})
export class MapViewComponent implements OnInit {
  minPanelSpace = 25; // Minimum percentage of vw the panel gets. Feeds into many other vars.
  draggableAreaLeftText = `${this.minPanelSpace}vw`;
  draggableAreaWidthText = `${100 - this.minPanelSpace}vw`;
  minPanelSpaceDecimal = this.minPanelSpace / 100;
  panelWidth: string = localStorage.getItem('widgetPanelWidth') ? localStorage.getItem('widgetPanelWidth') as string : `${window.innerWidth * this.minPanelSpaceDecimal}px`;
  maxDraggableWidth: number = window.innerWidth * (1 - this.minPanelSpaceDecimal);    // 75% of viewport
  minPanelWidth: number = window.innerWidth - this.maxDraggableWidth;                 // 25% of viewport
  dragLocation: string = `${parseInt(this.panelWidth) - this.minPanelWidth - 10}px`;  // Now relative to draggable area origin
  dragCursor: string = 'grab';
  startingWidth!: number;
  tabs!: Observable<Tab[]>;
  mapView!: MapView;
  webmapId: Observable<string>;
  webmapWidgets: Observable<WebmapWidgets[]>
  mapLeft = `${parseInt(this.dragLocation) + 10}px`;                      // Offset distance from absolute draggable area origin
  mapWidth = `${window.innerWidth - parseInt(this.panelWidth) - 11}px`;   // Variable width of map AFTER panel width and drag div
  isOpen = true;

  @ViewChild(WidgetPanelComponent, {read: ElementRef}) widgetPanel!: ElementRef;
  @ViewChild('dragBar', {static: true}) dragBar!: ElementRef;

  constructor(public activatedRoute: ActivatedRoute) {
    this.listenToRoutingData();
    this.webmapId = this.activatedRoute.data.pipe(map(d => d['webmapId']));
    this.webmapWidgets = this.activatedRoute.data.pipe(map(d => d['webmapWidgets'] as WebmapWidgets[]))
  }

  ngOnInit() {

  }

  listenToRoutingData() {
    this.tabs = this.activatedRoute.data.pipe(
      map(d => d['tabs'] as Tab[])
    );

  }

  captureStartingWidth(e: any) {
    this.startingWidth = this.widgetPanel.nativeElement.offsetWidth;
  }

  dragMove(e: any) {
    this.dragCursor = 'grabbing';
    this.panelWidth = `${this.startingWidth + e.distance.x}px`;
    localStorage.setItem('widgetPanelWidth', this.panelWidth);
    this.mapLeft = `${parseInt(this.panelWidth) - this.minPanelWidth}px`;   // No need to log to local storage; derived from panelWidth
    this.mapWidth = `${window.innerWidth - parseInt(this.panelWidth) - 11}px`;
  }

  dragEnd() {
    this.dragCursor = 'grab';
  }

  resetPanelWidth(e: any) {
    const currentPanelWidth = parseInt(this.panelWidth);
    this.panelWidth = `${window.innerWidth * this.minPanelSpaceDecimal}px`;
    const amountMoved = parseInt(this.panelWidth) - currentPanelWidth;
    // left is weird and you have to  keep adding (or adding negative) to it
    this.dragLocation = `${parseInt(this.dragLocation) + amountMoved}px`;
    localStorage.setItem('widgetPanelWidth', this.panelWidth);
    this.mapLeft = `${parseInt(this.panelWidth) - this.minPanelWidth}px`;
    this.mapWidth = `${window.innerWidth - parseInt(this.panelWidth) - 11}px`;
  }

  setMapView(mapView: MapView) {
    this.mapView = mapView;
  }


  protected readonly open = open;
}
