import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MapViewComponent} from './map-view/map-view.component';
import {WebmapComponent} from './webmap/webmap.component';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {CdkDrag} from '@angular/cdk/drag-drop';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatRadioModule} from '@angular/material/radio';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {WidgetPanelComponent} from './widget-panel/widget-panel.component';
import {MatTabsModule} from "@angular/material/tabs";
import {AuthService} from './services/auth.service';
import {MatMenuModule} from '@angular/material/menu';
import {HttpClientModule} from "@angular/common/http";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {ProjectAndProposalService} from './services/project-and-proposal.service';
import {AddDataFileDialogComponent} from './widgets/dialogs/add-data-file-dialog/add-data-file-dialog.component';
import {MatDialogModule} from "@angular/material/dialog";
import {AddDataErrorDialogComponent} from './widgets/dialogs/add-data-error-dialog/add-data-error-dialog.component';
import {BldMapViewComponent} from './bld-map-view/bld-map-view.component';

@NgModule({
  declarations: [
    AppComponent,
    MapViewComponent,
    WebmapComponent,
    WidgetPanelComponent,
    AddDataFileDialogComponent,
    AddDataErrorDialogComponent,
    BldMapViewComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatToolbarModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    CdkDrag,
    MatTooltipModule,
    MatRadioModule,
    MatInputModule,
    MatSelectModule,
    BrowserAnimationsModule,
    MatTabsModule,
    MatMenuModule,
    HttpClientModule,
    MatSnackBarModule,
    MatDialogModule
  ],
  providers: [AuthService, ProjectAndProposalService],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
